import PropTypes from "prop-types"
import React from "react"

import {
  Button,
  Card,
  ListGroup
} from 'react-bootstrap';


const IndexCard = ({title, items, button}) => (
  <Card style={{height: '100%'}}>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text>
        <ListGroup variant="flush">
          {items.map((item) => {
            return <ListGroup.Item>{item}</ListGroup.Item>
          })}
        </ListGroup>
      </Card.Text>
    </Card.Body>
    <Button className='mb-3 mx-3' href={button.link}>{button.text}</Button>
  </Card>    
);

IndexCard.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  button: PropTypes.object
}

IndexCard.defaultProps = {
  title: ``,
  items: [],
  button: {}
}

export default IndexCard;
