import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup
} from 'react-bootstrap';


const Footer = () => (
	<footer>
		<Container>
			<Row>
				<Col>
					Logan Besecker © {new Date().getFullYear()}
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer
